@use "../../helpers/mixins";
@use "../../helpers/breakpoints" as bp;
@use "../../helpers/brands/variables" as brands;
@use "./button--at";
@use "./button--golo-foods";
@use "./button--golo";

$small-button-font-size: 16px !default;
$small-button-line-height: 23px !default;
$large-button-font-size: 20px !default;
$large-button-line-height: 31px !default;
$extra-large-button-font-size: 18px !default;

$primary-button-text-color: brands.$primary-color !default;
$primary-button-color: brands.$button-primary-color !default;
$primary-button-hover-color: brands.$button-primary-hover-color !default;

$secondary-button-color: brands.$button-secondary-color !default;
$secondary-button-hover-color: brands.$button-secondary-hover-color !default;

$color-white: brands.$white !default;

$button-default-font-family: brands.$button-font-family-default !default;
$button-font-family-second-option: brands.$button-font-family-second-option !default;

@mixin styles {
  .gui.button {
    border-radius: 7px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    line-height: 23px;
    font-family: $button-default-font-family;
    letter-spacing: 0;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &--xl {
      width: 334px;
      height: 53px;
      font-size: $extra-large-button-font-size;

      @include bp.md {
        width: 432px;
      }
    }
    &--large {
      width: 225px;
      height: 55px;
      font-family: $button-font-family-second-option;
      @include mixins.section-text(
        $large-button-font-size,
        $large-button-line-height
      );
      @include bp.md {
        width: 255px;
      }
    }

    &--small {
      width: 190px;
      height: 49px;
      @include mixins.section-text(
        $small-button-font-size,
        $small-button-line-height
      );
      @include bp.md {
        width: 210px;
      }
    }

    &--primary {
      background-color: $primary-button-color;
      color: $primary-button-text-color;

      &:focus {
        color: $primary-button-text-color;
      }

      &:hover {
        background-color: $primary-button-hover-color;
        color: $color-white;
        cursor: pointer;
      }
    }

    &--secondary {
      color: $color-white;
      background-color: $secondary-button-color;

      &:hover {
        color: $color-white;
        background-color: $secondary-button-hover-color;
        cursor: pointer;
      }

      &:focus {
        color: $color-white;
      }
    }
  }

  // Brand specific styles
  .brand--at .gui.button.brand--default,
  .gui.button.brand--at {
    &.gui.button,
    .gui.button {
      @include button--at.styles;
    }
  }

  // Brand specific styles
  .brand--golo-foods .gui.button.brand--default,
  .gui.button.brand--golo-foods {
    &.gui.button,
    .gui.button {
      @include button--golo-foods.styles;
    }
  }

  .brand--golo .gui.button.brand--default,
  .gui.button.brand--golo {
    &.gui.button,
    .gui.button {
      @include button--golo.styles;
    }
  }
}
