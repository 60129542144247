@use "../../helpers/mixins";
@use "../../helpers/breakpoints" as bp;
@use "../../helpers/variables" as vars;
@use "../../helpers/brands/variables/at" as at-vars;

$primary-button-text-color: at-vars.$primary-color !default;
$primary-button-color: at-vars.$button-primary-color !default;
$primary-button-hover-color: at-vars.$button-primary-hover-color !default;

$secondary-button-color: at-vars.$button-secondary-color !default;
$secondary-button-hover-color: at-vars.$button-secondary-hover-color !default;

$color-white: at-vars.$white !default;

$button-default-font-family: at-vars.$button-font-family-default !default;
$button-font-family-second-option: at-vars.$button-font-family-second-option !default;

// Button component override variables
$box-shadow: at-vars.$box-shadow-color !default;
// END Button component override variables

@mixin styles {
  // Button component overrides
  box-shadow: 0px 3px 6px $box-shadow;
  border: none;
  transition: 0.3s;

  &--large {
    padding: 17px 16px 17px 16px;
    width: 290px;
    height: inherit;

    @include bp.sm {
      width: 330px;
    }
    @include bp.lg {
      width: 290px;
    }
    @include bp.xl {
      width: 350px;
    }
  }

  &--small {
    padding: 13px 16px 13px 16px;
    width: 210px;
    height: inherit;

    @include bp.xl {
      width: 255px;
    }
  }

  &--primary {
    color: $color-white;

    &:focus {
      color: $color-white;
    }
  }
  // END Button component overrides

  // Default styles that use AT Variables
  font-family: $button-default-font-family;

  &--primary {
    background-color: $primary-button-color;

    &:hover {
      background-color: $primary-button-hover-color;
      color: $color-white;
    }
  }

  &--secondary {
    background-color: $primary-button-color;
    color: $color-white;

    &:focus {
      color: $color-white;
    }

    &:hover {
      background-color: $primary-button-hover-color;
      color: $color-white;
    }
  }
}
