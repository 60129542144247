@use "./variables" as vars;

// change these values if its not imported into bootstrap
$grid-breakpoints-custom: (
  // Extra small screen / phone
  xs: vars.$breakpoint-xs,
  // Small screen / phone
  sm: vars.$breakpoint-sm,
  // Medium screen / tablet
  md: vars.$breakpoint-md,
  // Large screen / desktop
  lg: vars.$breakpoint-lg,
  // Wide desktop
  xl: vars.$breakpoint-xl,
  // Extra wide desktop
  xxl: vars.$breakpoint-xxl
) !default;

$breakpoints: $grid-breakpoints-custom;
@if vars.$use-bootstrap {
  $breakpoints: $grid-breakpoints;
}

// ========= functions =========
// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function bp-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}
// get's min bp #
// usage: bp-min(md, $breakpoints) finds md in the
//   $breakpoints array above and returns the number: 768 
@function bp-min($name, $breakpoints: $breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}
// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function bp-max($name, $breakpoints: $breakpoints) {
  $next: bp-next($name, $breakpoints);
  @return if($next, bp-min($next, $breakpoints) - .02, null);
}

// ========= mixins =========
// Usage: @include mq(lg) { ... }
// or:  @include mq(375) { ... }
// or:  @include mq($breakpoint-md) { ... }
@mixin mq($value, $direction: "min") {
  // checks if a number is used (including the var $breakpoint-xx)
  @if type-of($value) == number and unitless($value) {
    $value: $value + "px";
  }

  // checks if a string is used (like: lg, xs, xl, etc)
  // from 'key' in breakpoint map
  @if type-of($value) == string {
    @each $breakpoint-key, $breakpoint-value in $breakpoints {
      @if $breakpoint-key == $value {
        $value: $breakpoint-value;
      }
    }
  }

  // checks if it is max to reduce value by 1px
  @if $direction == "max" {
    $value: $value - 1;
  }

  // creates the media query
  @media only screen and (#{$direction}-width: #{$value}) {
    @content;
  }
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
// usage:  @include breakpoint-up(sm) {... for larger than sm: 576px ...}
@mixin breakpoint-up($name, $breakpoints: $breakpoints) {
  $min: bp-min($name, $breakpoints);
  @if $min {
    @include mq($min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin breakpoint-down($name, $breakpoints: $breakpoints) {
  $max: bp-max($name, $breakpoints);
  @if $max {
    @include mq($max, max) {
      @content;
    }
  } @else {
    @content;
  }
}

// usage:  @include xs { ... }
// or: @include xs(max) { ... }
@mixin xs($direction: "min") {
  @include mq("xs", $direction) {
    @content;
  }
}
@mixin sm($direction: "min") {
  @include mq("sm", $direction) {
    @content;
  }
}
@mixin md($direction: "min") {
  @include mq("md", $direction) {
    @content;
  }
}
@mixin lg($direction: "min") {
  @include mq("lg", $direction) {
    @content;
  }
}
@mixin xl($direction: "min") {
  @include mq("xl", $direction) {
    @content;
  }
}
@mixin xxl($direction: "min") {
  @include mq("xxl", $direction) {
    @content;
  }
}

// Media that spans multiple breakpoint widths from the $min
//   through the $max parameters.
// Makes the @content apply within range of the min and through max breakpoints
//
// examples:
// Set to specific min/max (defaults to px) -> @include bp.range(766, 1280);
//    another example -> @include bp.range(25rem, 60rem);
// Set to specific breakpoints (sm, md, lg, xl) -> @include bp.range(md, lg);
//   converts to -> @media only screen and (min-width: $breakpoints.md) and (max-width: $breakpoints.xl - 0.02)
//
// Exceptions/side effects
//   the "max" is included in the range.
//
@mixin range($lower, $upper, $breakpoints: $breakpoints) {
  $min: 0;
  $max: 0;
  // if $lower or $upper is a number, add px and use that instead of $min/$max
  // checks if a number is used (including the var $breakpoint-xx)
  @if type-of($lower) == number {
    @if unitless($lower) {
      $min: $lower + "px";
    } @else {
      $min: $lower;
    }
  } @else {
    $min: bp-min($lower, $breakpoints);
  } 

  @if type-of($upper) == number {
    @if unitless($upper) {
      $max: $upper + "px";
    } @else {
      $max: $upper;
    }
  } @else {
    $max: bp-max($upper, $breakpoints);
  }

  @if $min != null and $max != null {
    @media only screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media to use only a single breakpoint which is passed in.
//   only uses breakpoint names (sm, md, lg, xl)
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
//
// examples:
// Set to specific breakpoint -> @include bp.only(md);
//   converts to -> @media only screen and (min-width: 768px) and (max-width: 991.98px)
@mixin only($name, $breakpoints: $breakpoints) {
  @include range($name, $name) {
    @content;
  }
}
