@use "../../helpers/mixins";
@use "../../helpers/breakpoints" as bp;
@use "../../helpers/brands/variables" as brands;
@use "./modal--at";
@use "./modal--golo-foods";
@use "./modal--golo";

$modal-background-color: brands.$modal-background-color;
$box-shadow-color: brands.$box-shadow-color;
$modal-body-background-color: brands.$white;
$modal-body-default-font-family: brands.$paragraph-font-family-default;
$modal-body-font-size: 16px;
$modal-body-line-height: 1.5;

$modal-header-background-color: brands.$outline-color;
$modal-header-default-font-family: brands.$header-font-family-second-option;
$modal-header-font-color: brands.$primary-color;
$modal-header-font-size: 16px;
$modal-header-line-height: 1.5;
$modal-header-border-color: brands.$modal-border-color;

$modal-title-small-screen-font-size: 18px;
$modal-title-small-screen-line-height: 31px;
$modal-title-large-screen-font-size: 20px;
$modal-title-large-screen-line-height: 33px;
$modal-title-default-font-family: brands.$header-font-family-default;

$close-icon-hover-color: transparent;
$close-icon-circle-hover-color: brands.$white;

$modal-cancel-button-color: brands.$primary-color;
$modal-cancel-button-background-color: brands.$modal-action-cancel-color;
$modal-cancel-button-hover-color: brands.$white;
$modal-cancel-button-background-hover-color: brands.$modal-action-cancel-hover-color;

$modal-action-font-family: brands.$paragraph-font-family-default;
$modal-action-border-color: brands.$modal-border-color;
$modal-action-background-color: brands.$button-primary-color;

@mixin styles {
  .gui.modal {
    background-color: $modal-background-color;
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &.modal--open {
      display: block !important;
    }

    .modal__dialog {
      margin-top: 15px;
      max-width: 290px;
      @include mixins.mx-auto;
      box-shadow: 0px 3px 6px $box-shadow-color;
      border-radius: 15px;
      @include bp.sm {
        max-width: 450px;
        margin-top: 70px;
      }
      @include bp.md {
        max-width: 480px;
      }
      @include bp.lg {
        border-radius: 25px;
      }
      @include bp.xl {
        max-width: 540px;
        min-width: 540px;
        margin-top: 90px;
      }
    }
    .modal__body {
      padding: 65px 21px;
      background-color: $modal-body-background-color;
      font-family: $modal-body-default-font-family;
      @include mixins.section-text(
        $modal-body-font-size,
        $modal-body-line-height
      );

      @include bp.sm {
        padding: 65px 60px;
      }
      @include bp.md {
        padding: 65px 75px;
      }
      @include bp.lg {
        padding: 65px 80px;
      }
    }
    .modal__header {
      background-color: $modal-header-background-color;
      color: $modal-header-font-color;
      border-bottom: 2px solid $modal-header-border-color;
      font-family: $modal-header-default-font-family;
      border-radius: 15px 15px 0px 0px;
      text-align: center;
      @include bp.lg {
        border-radius: 25px 25px 0px 0px;
      }
      &__title {
        @include mixins.spacing-y(padding, 32px);
        @include mixins.spacing-y(margin, 0px);
        @include mixins.spacing-x(padding, 40px);
        @include mixins.section-text(
          $modal-title-small-screen-font-size,
          $modal-title-small-screen-line-height
        );
        width: 100%;
        font-family: $modal-title-default-font-family;
        font-weight: 500;

        @include bp.sm {
          @include mixins.spacing-x(padding, 60px);
          @include mixins.section-text(
            $modal-title-large-screen-font-size,
            $modal-title-large-screen-line-height
          );
        }
        @include bp.md {
          @include mixins.spacing-x(padding, 50px);
        }
        @include bp.xl {
          @include mixins.spacing-x(padding, 80px);
        }
        &__wrapper {
          display: flex;
          justify-content: center;
        }
      }
    }

    .modal__icon--close {
      padding: 0px;
      border: none;
      border-radius: 40px;
      height: 29px;
      width: 29px;
      position: relative;
      top: 10%;

      @include bp.sm {
        top: 12%;
      }

      @include bp.md {
        top: 12%;
      }

      @include bp.lg {
        height: 36px;
        width: 36px;
        top: 12%;
      }

      &:hover {
        background-color: $close-icon-hover-color;
        svg {
          height: 29px;
          width: 29px;
          @include bp.md {
            height: 36px;
            width: 36px;
          }
          circle {
            fill: $close-icon-circle-hover-color;
          }
        }
      }

      &__wrapper {
        width: 0px;
        position: relative;
        top: 13%;
        right: 13%;
        @include bp.sm {
          right: 9%;
        }
        @include bp.md {
          right: 8%;
        }
        @include bp.lg {
          right: 11%;
        }
        @include bp.xl {
          right: 9%;
        }
      }
    }

    .modal__action {
      &.button {
        &--secondary {
          color: $modal-cancel-button-color;
          background-color: $modal-cancel-button-background-color;

          &:hover {
            color: $modal-cancel-button-hover-color;
            background-color: $modal-cancel-button-background-hover-color;
          }
        }
      }

      border-radius: 0;
      font-family: $modal-action-font-family;
      max-width: 100%;
      width: 100%;

      &__wrapper {
        background-color: $modal-action-background-color;
        border-radius: 0px 0px 15px 15px;
        border-top: 2px solid $modal-action-border-color;
        display: flex;
        overflow: hidden;
        @include bp.lg {
          border-radius: 0px 0px 25px 25px;
        }
      }
    }
  }

  // Brand specific styles
  .brand--at .gui.modal.brand--default,
  .gui.modal.brand--at {
    &.gui.modal,
    .gui.modal {
      @include modal--at.styles;
    }
  }

  // Brand specific styles
  .brand--golo-foods .gui.modal.brand--default,
  .gui.modal.brand--golo-foods {
    &.gui.modal,
    .gui.modal {
      @include modal--golo-foods.styles;
    }
  }

  .brand--golo .gui.modal.brand--default,
  .gui.modal.brand--golo {
    &.gui.modal,
    .gui.modal {
      @include modal--golo.styles;
    }
  }
}
