@use "./variables" as vars;

/* SCSS Mixins */

/* Mixin for section text
   pass in: font-size (px/em/rem), line-height (px/em/rem), font-weight, color
   don't need one of them? pass it as a 0 if before one you do need
   otherwise don't include
   also - !important can be added any of the 4 parameters
 - example 1: don't need font-weight:  @include section-text(18px, 20px, 0, #FFFFFF)
 - example 2: don't need font-weight or color:  @include section-text(2em, 3em)
 - example 3: use !important:  @include section-text(2rem !important, 2.5rem !important)
*/
@mixin section-text($ft: 0, $lh: 0, $fw: 0, $color: 0) {
  @if $ft != 0 {
    font-size: $ft;
  }
  @if $lh != 0 {
    line-height: $lh;
  }
  @if $fw != 0 {
    font-weight: $fw;
  }
  @if $color != 0 {
    color: $color;
  }
}

// Mixin for spacing x (type: margin/padding, left and right)
// Defining only `$left` will result in both the left and right having the same
// - example: @include spacing-x(padding, 20px, 4em)
// - example: @include spacing-x(margin, 20px)
@mixin spacing-x($type, $left, $right: null) {
  #{$type}-left: $left;

  @if $right == null {
    #{$type}-right: $left;
  } @else {
    #{$type}-right: $right;
  }
}

// Mixin for spacing for y (type: margin/padding, top and bottom)
// Defining only `$top` will result in both the top and bottom having the same
// - example: @include spacing-y(margin, 20px, 4em)
// - example: @include spacing-y(padding, 20px)
@mixin spacing-y($type, $top: 0, $bottom: null) {
  #{$type}-top: $top;

  @if $bottom == null {
    #{$type}-bottom: $top;
  } @else {
    #{$type}-bottom: $bottom;
  }
}

// Mixin for margin auto - created this since it is used so often
// - example:  @include m-auto
@mixin mx-auto {
  @include spacing-x(margin, auto, auto);
}

/*  
  font-size: clamp(min, rate of change, max)
   -- min = mobile & max = desktop??
  example:  font-size: clamp(28px, 16px * 2vw, 40px);
  vw = 1% of device viewport -- example 375px wide = 3.75vw
  28px / 375 = 7.5% = 7.5vw

  ** using REM allows for accessibility
  REM = size you want / base font size
  example:  28px / 16px = 1.75rem

  function:

  @use 'sass:math';
  @function rem($pixel) {
    // divide $pixel by 16 (base font size) to return rems
    @if math.is-unitless($pixel) {
      $result: math.div($pixel, 16) + rem;
      @return $result;
    }
    // if $pixel has a unit, thow an error
    @else {
      @error "Don't use units - only numbers in rem() function;
    }
  }

  example:  font-size: clamp(rem(28), rem(16) + 2vw, rem(40));
  can create an em version of function


*/

/// Adds a transition property to an element.
@mixin transition(
  $property: all,
  $duration: 0.3s,
  $timing-func: ease-out,
  $delay: 0s
) {
  @each $name, $prefix in vars.$browser-prefixes {
    #{$prefix}transition: $property $duration $timing-func $delay;
  }
}

/// Adds a blur effect with a given radius
@mixin blur($radius: 2px) {
  -webkit-filter: blur($radius);
  -moz-filter: blur($radius);
  -o-filter: blur($radius);
  -ms-filter: blur($radius);
  filter: blur($radius);
}

// creates 'flex-box' using common passed in settings
// note: use 'null' if you wish to use the default for any of the first 2 parameters
//    do not give any parameters if you want to use the default for all 3
// - example: @include mixins.flex-box();
// - example: @include mixins.flex-box(null, flex-end, flex-start);
// - example: @include mixins.flex-box(row, space-around, flex-start);
@mixin flex-box(
  $flexDirection: column,
  $justifyContent: center,
  $alignItems: center
) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $flexDirection;
}

// creates classes for divider thickness based on what is passed in
// @param {String} $begin-string - beginning of class name - include '.' if not starting with '&'
// @param {Number} $startValue - starting value of thickness range
// @param {Number} $endValue - ending value (includes this value) of thickness range
// @param {Number} $step - incremental value
@mixin divider-thickness(
  $begin-string: "&--thickness-",
  $startValue: 1,
  $endValue: 3,
  $step: 0.5
) {
  $value: $startValue;

  @while $value <= $endValue {
    $class-value: $value * 10;

    #{$begin-string}#{$class-value} {
      border-width: $value * 1px;
    }
    $value: $value + $step;
  }
}

/// Generates the styling for the circles as well as the animation each
/// circle performs - including the animation colors, duration and
/// delay - for the loading indicator. The delay is a calculation of the
/// `$keyframeDuration`, and is currently set to 10% of the duration
/// (i.e. if the duration is 2 seconds, the delay between dots will be
/// 0.2 seconds).
///
/// @param {string} $keyframeName [load] - The name of the keyframes.
/// @param {number} $keyframeDuration [2]
///   The animation duration for each dot.
/// @param {number} $numberOfCircles [5]
///   The number of circles to render.
/// @param {string} $startColor [#006faf]
///   The color of the dot when it starts its animation.
/// @param {string} $initialColor [#ffffff]
///   The color of the dots before and at the end of their animation.
/// @output The styling to be embedded within the loading indicator class.
@mixin keyframeGenerator(
  $keyframeName: load,
  $keyframeDuration: 2,
  $numberOfCircles: 5,
  $startColor: #006faf,
  $initialColor: #ffffff
) {
  svg {
    vertical-align: middle;
  }

  #loading-circles {
    circle {
      fill: $initialColor;
    }

    #circle {
      @for $i from 1 through $numberOfCircles {
        &#{$i} {
          animation: $keyframeName
            #{$keyframeDuration}s
            infinite
            #{$keyframeDuration *
            0.1 *
            ($i - 1)}s;
        }
      }
    }
  }

  @keyframes #{$keyframeName} {
    0% {
      fill: $startColor;
    }

    100% {
      fill: $initialColor;
    }
  }

  @content;
}

@mixin operetta-regular {
  font-family: "operetta-12", serif;
  font-weight: 400;
}

@mixin operetta-light {
  font-family: "operetta-12", serif;
  font-weight: 300;
}
