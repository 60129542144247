@use "../../helpers/mixins";
@use "../../helpers/breakpoints" as bp;
@use "../../helpers/variables" as vars;
@use "../../helpers/brands/variables/golo" as golo-vars;

$primary-button-text-color: golo-vars.$primary-color !default;
$primary-button-color: golo-vars.$button-primary-color !default;
$primary-button-hover-color: golo-vars.$button-primary-hover-color !default;

$secondary-button-color: golo-vars.$button-secondary-color !default;
$secondary-button-hover-color: golo-vars.$button-secondary-hover-color !default;

$color-white: golo-vars.$white !default;

$button-default-font-family: golo-vars.$button-font-family-default !default;
$button-font-family-second-option: golo-vars.$button-font-family-second-option !default;

@mixin styles {
  // Default styles that use AT Variables
  font-family: $button-default-font-family;

  &--primary {
    background-color: $primary-button-color;
    color: $primary-button-text-color;

    &:focus {
      color: $primary-button-text-color !important;
    }

    &:hover {
      background-color: $primary-button-hover-color;
      color: $color-white !important;
    }
  }

  &--secondary {
    color: $color-white;
    background-color: $secondary-button-color;

    &:hover {
      color: $color-white !important;
      background-color: $secondary-button-hover-color;
      cursor: pointer;
    }

    &:focus {
      color: $color-white !important;
    }
  }
}
