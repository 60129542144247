$primary-color: #006faf;
$secondary-color: #ff6c00;
$tertiary-color: #6bb64c;
$text-color: #767676;
$button-primary-color: #e4f1fe;
$button-primary-hover-color: #006faf;
$button-secondary-color: #16882b;
$button-secondary-hover-color: #4ba541;
$button-font-family-default: "NimbusSansNovusDMedium";
$button-font-family-second-option: "NimbusSansNovusDSemiBold";
$header-font-family-default: "Encode Sans Condensed";
$header-font-family-second-option: "NimbusSansNovusDMedium";
$subheader-font-family-default: "Encode Sans";
$subheader-font-family-second-option: "NimbusSansNovusDMedium";
$paragraph-font-family-default: "Encode Sans";
$quote-font-family-default: "Encode Sans Condensed";
$label-font-family-default: "Encode Sans";
$label-font-family-second-option: "NimbusSansNovusDMedium";
$bullet-text-font-family-default: "Encode Sans";
$image-background-fill: #f7f7f8;
$inactive-pagination: #cccccc;
$outline-color: #e4f1fe;
$divider-color: #e8e9eb;
$white: #ffffff;
$gradient-color: #eff7ed;
$fda-statement-border-color: #000000;
$box-shadow-color: #00000029;
$dropdown-item-hover-background-color: #e4f1fe;
$background-color-default: transparent;
$background-color-primary: #eff7ed;
$background-color-secondary: #f3f3f3;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$cms-spacer-background-color: #eff7ed;
$play-button-circle-color: #e4f1fe;
$play-button-triangle-color: #90c788;
$video-playbar-color: #006faf;
$modal-border-color: #e4f1fe;
$modal-background-color: rgba(255, 255, 255, 0.85);
$modal-action-cancel-color: #f1f1f1;
$modal-action-cancel-hover-color: #d3d3d3;
