@use "../../helpers/mixins";
@use "../../helpers/breakpoints" as bp;
@use "../../helpers/brands/variables/at" as at-vars;

$modal-background-color: at-vars.$modal-background-color;
$box-shadow-color: at-vars.$box-shadow-color;
$modal-body-background-color: at-vars.$white;
$modal-body-default-font-family: at-vars.$paragraph-font-family-default;
$modal-body-font-size: 16px;
$modal-body-line-height: 1.5;

$modal-header-background-color: at-vars.$outline-color;
$modal-header-default-font-family: at-vars.$header-font-family-second-option;
$modal-header-font-color: at-vars.$primary-color;
$modal-header-font-size: 16px;
$modal-header-line-height: 1.5;
$modal-header-border-color: at-vars.$modal-border-color;

$modal-title-small-screen-font-size: 18px;
$modal-title-small-screen-line-height: 31px;
$modal-title-large-screen-font-size: 20px;
$modal-title-large-screen-line-height: 33px;
$modal-title-default-font-family: at-vars.$header-font-family-default;

$close-icon-hover-color: transparent;
$close-icon-circle-hover-color: at-vars.$white;

$modal-cancel-button-color: at-vars.$primary-color;
$modal-cancel-button-background-color: at-vars.$modal-action-cancel-color;
$modal-cancel-button-hover-color: at-vars.$white;
$modal-cancel-button-background-hover-color: at-vars.$modal-action-cancel-hover-color;

$modal-action-font-family: at-vars.$paragraph-font-family-default;
$modal-action-border-color: at-vars.$modal-border-color;
$modal-action-background-color: at-vars.$button-primary-color;

@mixin styles {
  background-color: $modal-background-color;

  .modal__dialog {
    box-shadow: 0px 3px 6px $box-shadow-color;
  }
  .modal__body {
    background-color: $modal-body-background-color;
    font-family: $modal-body-default-font-family;
    @include mixins.section-text(
      $modal-body-font-size,
      $modal-body-line-height
    );
  }
  .modal__header {
    background-color: $modal-header-background-color;
    color: $modal-header-font-color;
    border-bottom: 2px solid $modal-header-border-color;
    font-family: $modal-header-default-font-family;
    &__title {
      @include mixins.section-text(
        $modal-title-small-screen-font-size,
        $modal-title-small-screen-line-height
      );
      font-family: $modal-title-default-font-family;

      @include bp.sm {
        @include mixins.section-text(
          $modal-title-large-screen-font-size,
          $modal-title-large-screen-line-height
        );
      }
    }
  }

  .modal__icon--close {
    &:hover {
      background-color: $close-icon-hover-color;
      svg {
        circle {
          fill: $close-icon-circle-hover-color;
        }
      }
    }
  }

  .modal__action {
    &.button {
      &--secondary {
        color: $modal-cancel-button-color;
        background-color: $modal-cancel-button-background-color;

        &:hover {
          color: $modal-cancel-button-hover-color;
          background-color: $modal-cancel-button-background-hover-color;
        }
      }
    }

    font-family: $modal-action-font-family;

    &__wrapper {
      background-color: $modal-action-background-color;
      border-top: 2px solid $modal-action-border-color;
    }
  }
}
